#plan-selected .affix {
    top: 10px;
    width: 28.42%;
}

.pricing_description {
    border: 1px solid #d4d4d4;
}

.pricing {
    background: #fff;
}

.pricing .title {
    background: #1f8329;
    height: 110px;
    padding: 15px 0 0;
    text-align: center;
    color: #fff !important;
}

.pricing .title h2 {
    text-transform: capitalize;
    font-size: 18px;
    border-radius: 5px 5px 0 0;
    margin: 0;
    font-weight: 400;
    color: #fff !important;
}

.pricing .title h1 {
    font-size: 30px;
    margin: 12px;
    color: #fff !important;
}

.pricing .title span {
    background: rgba(51, 51, 51, 0.28);
    padding: 2px 5px;
}

.pricing_features {
    background: #FAFAFA;
    padding: 20px 15px;
    font-size: 13.5px;
    overflow-y: scroll;
    border: 1px solid #d4d4d4;
}

.pricing_features ul li {
    margin-left: 10px;
}

.pricing_features ul {
    margin-bottom: 0 !important;
}

.pricing_footer {
    padding: 8px;
    background-color: #f5f5f5;
    text-align: center;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    display: inline-flex;
    border: 1px solid #d4d4d4;
    width: 100%;
}

.pricing_footer p {
    font-size: 13px;
    padding: 10px 0 2px;
    display: block;
}

.ui-ribbon-container {
    position: relative;
}

.ui-ribbon-container .ui-ribbon-wrapper {
    position: absolute;
    overflow: hidden;
    width: 140px;
    height: 100px;
    top: -2px;
    right: -2px;

}

.ui-ribbon-container.ui-ribbon-primary .ui-ribbon {
    background-color: #5b90bf;
}

.ui-ribbon-container .ui-ribbon {
    position: relative;
    display: block;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    transform: rotate(34deg);
    padding: 7px 0;
    left: -5px;
    top: 15px;
    width: 168px;
    line-height: 20px;
    background-color: #555;
    box-shadow: 0 0 3px rgba(0,0,0,.3);
}

.ui-ribbon-container.ui-ribbon-primary .ui-ribbon:after, .ui-ribbon-container.ui-ribbon-primary .ui-ribbon:before {
    border-top: 2px solid #5b90bf;
}

.ui-ribbon-container .ui-ribbon:before {
    left: 0;
    bottom: -1px;
}

.ui-ribbon-container .ui-ribbon:before {
    right: 0;
}

.ui-ribbon-container .ui-ribbon:after, .ui-ribbon-container .ui-ribbon:before {
    position: absolute;
    content: " ";
    line-height: 0;
    border-top: 2px solid #555;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
}

section#pricing {
    background-image: url("/images/pricing-bg.png");
    background-size: 30%;
}

.pricing-container {
    display: flex;
    justify-content: center;
    padding: 40px 0;
}

@media (min-width: 992px) {
    .col-lg-offset-1-and-half {
        margin-left: 12.499999995%;
    /*so you multiply 8.33333333 * 1.5 = 12.499999995%*/
    }
}

.list-features {
    padding-top: 8%;
}

.list-features li {
    border-bottom: 1px dashed rgba(86, 86, 86, 0.29);
    font-size: 1.1em;
    margin-bottom: 20px;
}

.list-features li p:before {
    content: '- ';
}

.padding-container {
    padding: 10px !important;
}

.system-description {
    font-size: 1.3em;
}