.list-group-item-selected {
  background-color: #2e7d322e !important;
}

#requests-services .list-group-item {
  padding: 0 !important;
}

#requests-services li label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 19px;
  margin-bottom: 0 !important;
  transition: background-color ease 0.5s;
}

#requests-services li label:hover {
  background-color: #e0e0e0;
}

#requests-services li input {
  float: right;
}

.pointer {
  cursor: pointer;
}

/* Altera a cor do botão primary para verde padrão */

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #1f8128 !important;
}

/* --- granttjs custon style --- */

.gtaskname div,
.gtaskname {
  font-size: 15px !important;
}

.gtaskheading,
.gname,
.gtaskname,
.gresource,
.gduration,
.gpccomplete,
.gstartdate,
.genddate {
  height: 18px;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.gresource,
.gduration,
.gpccomplete,
.gstartdate div,
.gstartdate {
  text-align: center !important;
  font-size: 12px !important;
}

.gtaskheading,
.gmajorheading,
.gminorheading {
  font-size: 13px !important;
  white-space: nowrap;
}

.gtaskcellwkend,
.gminorheadingwkend {
  background-color: #fff5f5 !important;
  color: darkred !important;
  font-weight: bold !important;
  font-size: 12px !important;
  white-space: nowrap;
}

.genddate div,
.genddate {
  font-size: 12px !important;
}

.gtaskRedDelos {
  background: rgb(80, 193, 58) !important;
  background: linear-gradient(
    to bottom,
    rgba(80, 193, 58, 1) 0%,
    rgba(88, 209, 64, 1) 20%,
    rgba(102, 237, 75, 1) 40%,
    rgba(80, 193, 58, 1) 70%,
    rgba(53, 132, 37, 1) 100%
  ) !important;
}

.gtaskRedDelos {
  height: 13px;
  filter: alpha(opacity=90);
  opacity: 0.9;
  margin-top: 1px;
}

.gtaskRedDelos .gtaskRedDeloscomplete {
  float: left;
  overflow: hidden;
  height: 5px;
  filter: alpha(opacity=40);
  opacity: 0.7;
  background-color: darkred !important;
  margin-top: 4px;
}

.gpccomplete,
.ggroupcaption,
.gcaption,
.gTIc {
  display: none !important;
}

.glistgrid,
.glistlbl {
  width: 390px !important;
}

/* --- /granttjs custon style/ --- */

/* Remove a cor azul das datas do proximo mês do background color */

.bootstrap-datetimepicker-widget table td.old,
.bootstrap-datetimepicker-widget table td.new {
  background-color: #fff !important;
}

.modal-open {
  overflow: hidden !important;
  padding-right: 0 !important;
}

.debit-memo-alert-exclude {
  color: #fff;
  margin-left: 8px;
}

.debit-memo-alert {
  font-size: 12px;
  padding-top: 5px;
  margin-left: 5px;
}

.table-responsive {
  min-height: 390px;
}

.delete {
  cursor: pointer;
}

#projects_details_clients td {
  padding: 0;
}

.modal-backdrop.in {
  opacity: 0.5 !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.doubleScroll-scroll-wrapper {
  margin-bottom: 19px;
}

@media (min-width: 1200px) {
  .nopadding {
    padding: auto !important;
    margin: 0 !important;
  }
  .project-content {
    padding-top: 0px !important;
  }
}

@media (min-width: 1024px) {
  .dropdown-menu-right {
    left: auto !important;
  }
}

.material-scrolltop.reveal {
  z-index: 99;
}

.modal {
  z-index: 9999;
}

.col-md-55,
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  float: left !important;
}

.table-details-title {
  display: none;
}

.th-project {
  min-width: 143px;
}

.nopadding {
  padding: 0 !important;
}

@media (max-width: 768px) {
  .nopadding {
    padding: 0 !important;
  }
}

.hr-text {
  border-color: #969696;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  margin-bottom: 20px;
}

.hr-text:before {
  content: "";
  background: -webkit-linear-gradient(left, transparent, #818078, transparent);
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;
  padding: 0 0.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: rgba(255, 255, 255, 0.92);
}

@media print {
  .progress {
    position: relative;
  }
  .progress:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    border-bottom: 2rem solid #eeeeee;
  }
  .progress-bar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border-bottom: 2rem solid #337ab7;
  }
  .progress-bar-success {
    border-bottom-color: #67c600;
  }
  .progress-bar-info {
    border-bottom-color: #5bc0de;
  }
  .progress-bar-warning {
    border-bottom-color: #f0a839;
  }
  .progress-bar-danger {
    border-bottom-color: #ee2f31;
  }
}

#modal-details .tablesorter-header-inner {
  float: left;
}

.money:before {
  content: "R$ ";
}

body
  > div.container.body
  > div
  > div.right_col
  > main
  > div
  > div
  > div:nth-child(3)
  > ul {
  border-bottom: 1px solid #ddd;
}

.table-details .text-danger {
  color: #e51c23 !important;
}

.table-details td {
  cursor: pointer;
}

.popover-title {
  color: #565656;
  background-color: #fff;
}

.modal-backdrop {
  z-index: 1070;
}

.modal {
  z-index: 1080;
}

.alert {
  margin-top: 10px;
}

.alert .bold {
  color: #fff;
}

.affix {
  position: fixed !important;
}

div.alert h1,
div.alert h2,
div.alert h3,
div.alert h4,
div.alert h5,
div.alert h6,
div.alert .h1,
div.alert .h2,
div.alert .h3,
div.alert .h4,
div.alert .h5,
div.alert .h6 {
  color: #fff;
}

.alert-success {
  background: #134e5e;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2ab336, #134e5e);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2ab336, #134e5e);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

span.line {
  float: left;
  width: 100%;
  height: 2px;
  background: rgba(193, 193, 193, 0.41);
  border-top: 1px dashed #565656;
  margin-top: 20px;
  margin-bottom: 20px;
}

.jumbotron {
  background-color: rgba(193, 193, 193, 0.41) !important;
  padding: 10px 21px !important;
}

.nav.side-menu li:hover:not(.nav-divider) {
  background: rgba(255, 255, 255, 0.05);
}

.alert {
  margin-bottom: 0;
}

.alert > button.close {
  color: #fff;
  opacity: 1;
  margin-top: -4px;
}

.asterisk-required {
  color: red;
}

.dropdown-menu {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.bootstrap-select.btn-group .dropdown-menu {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

#projects_details_clients .list-group {
  margin: 0;
  border: 0;
  box-shadow: none;
  border-radius: 0;
}

#projects_details_clients .list-group .list-group-item {
  border-radius: 0;
  border: 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  color: #333;
}

#projects_details_clients .list-group .list-group-item:first-child {
  border-top: 0;
}

#projects_details_clients .list-group .list-group-item:last-child {
  border-bottom: 0;
}

#text,
#description,
#note {
  resize: none;
}

.count_message {
  background-color: smoke;
  margin-top: -20px;
  margin-right: 5px;
}

.bootstrap-select > .dropdown-menu ul li > a {
  white-space: normal;
}

#tasks_modal.modal .modal-body {
  overflow-y: auto;
}

input.receipt {
  background-color: #ddd !important;
  cursor: not-allowed !important;
}

input.receipt:focus {
  border: none;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
}

.true {
  color: #2d8c33 !important;
}

.false {
  color: #a94442 !important;
}

ul[role="listbox"] {
  width: 95%;
}

.issue_date_calendar {
  text-align: center;
  font-weight: bold;
}

#form-expenses .bootstrap-select .dropdown-menu {
  max-width: 200px;
}

#form-expenses .dropdown-menu .inner {
  overflow-x: hidden;
}

.table-hours tr td {
  padding: 0 7px;
}

.bold {
  font-weight: bold;
  color: #565656;
}

.dct-color {
  color: #1b5e20;
}

.report-xlsx {
  cursor: pointer;
}

.panel-dct {
  border-color: #dddee0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  color: inherit;
}

.panel-heading {
  padding: 16px 24px;
  border-bottom: 1px solid transparent;
  border-radius: 3px 3px 0 0;
  border-bottom: 1px solid #eaebec;
}

.panel-body {
  padding: 16px 24px;
}

.panel-footer {
  padding: 16px 8px;
  background-color: #f2f3f3;
  border-color: #eaebec;
  border-radius: 0 0 3px 3px;
}

.shadow {
  -webkit-box-shadow: 0px 16px 20px -1px rgba(241, 241, 241, 241.99);
  -moz-box-shadow: 0px 16px 20px -1px rgba(241, 241, 241, 241.99);
  box-shadow: 0px 16px 20px -1px rgba(241, 241, 241, 241.99);
}

.footer {
  bottom: 0;
  width: 100%;
}

.footer > .container {
  padding: 20px;
}

.nomargin {
  margin: 0;
}

.btn-dct {
  color: #fff;
  background-color: #1f8128;
  border-color: #133615;
}

.btn-dct:active {
  color: #ffffff;
  background-color: #279e31;
  border-color: #122913;
}

.btn-dct.active,
.btn-dct:active {
  color: #ffffff;
  background-color: #279e31;
  border-color: #122913;
}

.open > .dropdown-toggle.btn-dct:focus,
.open > .dropdown-toggle.btn-dct:hover {
  color: #ffffff;
  background-color: #279e31;
  border-color: #122913;
}

.btn-dct.focus,
.btn-dct:focus,
.btn-dct:hover {
  color: #fff;
  text-decoration: none;
}

.toggle-on {
  margin: 0px !important;
  padding: 6px 14px 0px 0px !important;
}

.toggle-off {
  margin: 0px !important;
  padding: 8px 0 0 15px !important;
}

.table-hover > tbody > tr:hover {
  background-color: #dff0d8;
}

.selectpicker .input-sm {
  height: 30px;
  line-height: 30px;
}

.signin {
  margin-top: 5%;
}

.selectlabel {
  width: 100%;
  height: 100%;
  cursor: Pointer;
  padding: 1px;
  margin-bottom: 0px;
}

#form-projects ul > li.disabled.selected.active > a > span {
  color: #fff;
}

.form-signin {
  max-width: 400px;
  padding: 25px;
  margin: auto;
  border: 1px solid #ccc;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 90px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-color: rgba(255, 255, 255, 0.92);
}

.form-signin:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-color: rgba(255, 255, 255, 0.92);
}

.form-register {
  max-width: 100%;
  padding: 25px;
  margin: auto;
  border: 1px solid #ccc;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 90px;
  background-color: rgba(255, 255, 255, 0.7);
  transition: all 0.7s ease;
}

.form-register:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-color: rgba(255, 255, 255, 0.92);
}

.form-signin2 {
  max-width: 400px;
  padding: 25px;
  margin: auto;
  border: 1px solid #ccc;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 90px;
  background-color: #fff;
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 15px;
  margin-top: 0;
}

.form-signin2 .form-signin-heading,
.form-signin2 .checkbox {
  margin-bottom: 15px;
  margin-top: 0;
}

.form-signin .checkbox {
  font-weight: normal;
}

.form-signin .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}

.form-signin2 .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2 !important;
}

.form-signin input[type="email"]:hover {
  z-index: 2 !important;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-full {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

.btn-group > .btn {
  margin-left: 0;
  margin-right: 0;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: normal;
}

.progress {
  margin-bottom: 0px;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 3px 0 0 0;
  border-radius: 4px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #1b5e20;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  font-weight: bold;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #2e7d32;
  border-color: #1b5e20;
  font-weight: bold;
}

.table-bordered > thead > tr > th {
  border: 1px solid #ddd;
  background-image: -webkit-linear-gradient(top, #fff 0, #fbfbfb 100%);
  background-image: -o-linear-gradient(top, #fff 0, #fbfbfb 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#fbfbfb)
  );
  background-image: linear-gradient(to bottom, #fff 0, #fbfbfb 100%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe0e0e0', GradientType=0);
  filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
  background-repeat: repeat-x;
  vertical-align: middle;
}

.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border-bottom-width: 2px;
  border-bottom-color: #66bb6a;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 13px;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #2e7d32 !important;
  border-color: #122913;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-image: none;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
  background-color: #2e7d32 !important;
  border-color: #122913;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-image: none;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-color: #2e7d32 !important;
  border-color: #122913;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-image: none;
}

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
  background: #eee;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: rgb(238, 238, 238);
  cursor: pointer;
  background-image: none;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background: #eee;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: rgb(238, 238, 238);
  cursor: pointer;
  background-image: none;
}

.bootstrap-datetimepicker-widget table td.today:before {
  content: "";
  display: inline-block;
  border: solid transparent;
  border-width: 0 0 7px 7px;
  border-bottom-color: #122913;
  border-top-color: #122913;
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.toggle-off.btn {
  padding-left: 16px;
}

a {
  color: #2e7d32;
  text-decoration: none;
}

.tab-content > .active {
  border: 1px solid;
  border-width: 0 1px 1px;
  border-color: #ddd;
  display: block;
  padding: 20px;
}

@media (max-width: 767px) {
  .table-responsive .dropdown-menu,
  .table-responsive .dropdown-toggle {
    position: static !important;
  }
  *[data-toggle="tooltip"] {
    display: none;
  }
}

.table-dropdown-visible {
  overflow: visible !important;
}

.cursor {
  border: 12px solid transparent;
  color: #cccccc !important;
  transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
}

.cursor:hover {
  border: 12px solid #134217;
  box-shadow: #134217 2px 2px 10px;
  background-color: #134217;
  background-color: #fff !important;
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 999;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  -ms-transform: rotate(360deg);
  /* IE 9 */
  -webkit-transform: rotate(360deg);
  /* Chrome, Safari, Opera */
  transform: rotate(360deg);
}

.dct-card {
  border: 12px solid transparent;
  color: #cccccc !important;
  transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
}

.dct-card:hover {
  border: 12px solid #e0e0e0;
  box-shadow: #e0e0e0 2px 2px 10px;
  background-color: #e0e0e0;
  background-color: #fff !important;
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
  z-index: 999;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.bg-card {
  background-image: -webkit-linear-gradient(top, #fff 0, #fbfbfb 100%);
  background-image: -o-linear-gradient(top, #fff 0, #fbfbfb 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#fbfbfb)
  );
  background-image: linear-gradient(to bottom, #fff 0, #fbfbfb 100%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe0e0e0', GradientType=0);
  filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
  background-repeat: repeat-x;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  background-color: #1b5e20;
  background-image: -webkit-linear-gradient(top, #26862d 0, #1b5e20 100%);
  background-image: -o-linear-gradient(top, #26862d 0, #1b5e20 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#26862d),
    to(#1b5e20)
  );
  background-image: linear-gradient(to bottom, #26862d 0, #1b5e20 100%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);
  background-repeat: repeat-x;
}

.bg-gradient {
  text-shadow: 0 1px 0 #fff;
  background-image: -webkit-linear-gradient(top, #fff 0, #e0e0e0 100%);
  background-image: -o-linear-gradient(top, #fff 0, #e0e0e0 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#e0e0e0)
  );
  background-image: linear-gradient(to bottom, #fff 0, #e0e0e0 100%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe0e0e0', GradientType=0);
  filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
  background-repeat: repeat-x;
  border-color: #dbdbdb;
  border-color: #ccc;
}

.form-control:focus {
  border-color: #26862d;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(27, 94, 32, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(27, 94, 32, 0.6);
}

.animation-01 {
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
}

.animation-02 {
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}

.animation-03 {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}

.animation-04 {
  animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
}

.animation-05 {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}

.animation-06 {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}

.animation-07 {
  animation-delay: 0.7s;
  -webkit-animation-delay: 0.7s;
}

.animation-08 {
  animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
}

.animation-09 {
  animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
}

.animation-1 {
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}

.dct-red {
  color: #c9302c;
}

.dct-yellow {
  color: #ec971f;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: rgb(46, 125, 50);
}

.bootstrap-datetimepicker-widget table td span.active {
  background-color: rgb(46, 125, 50);
}

.ranges {
  display: none;
}

.dropdown-menu .inner > li.disabled.selected.active a {
  color: #fff;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: rgb(46, 125, 50);
}

.bootstrap-datetimepicker-widget table td span.active {
  background-color: rgb(46, 125, 50);
}

.ranges {
  display: none;
}

.toggle.btn {
  float: left;
}

#nd-code {
  border-bottom: 1px solid #3c3c3c;
}

.table-projects-options {
  background-color: #fff;
}

.text-muted {
  color: #777;
}

textarea,
textarea.form-control,
input.form-control,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
[type="text"].form-control,
[type="password"].form-control,
[type="email"].form-control,
[type="tel"].form-control,
[contenteditable].form-control {
  -webkit-box-shadow: inset 0 -1px 0 rgba(102, 102, 102, 0.64);
  box-shadow: inset 0 -1px 0 rgba(102, 102, 102, 0.64);
}

select,
select.form-control {
  -webkit-box-shadow: inset 0 -1px 0 rgba(102, 102, 102, 0.64) !important;
  box-shadow: inset 0 -1px 0 rgba(102, 102, 102, 0.64) !important;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  border: 1px solid rgba(102, 102, 102, 0.64);
}

.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus:hover {
  border: none;
  color: #1b5e20 !important;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus:hover {
  background-color: transparent;
  -webkit-box-shadow: inset 0 -2px 0 #1b5e20 !important;
  box-shadow: inset 0 -2px 0 #1b5e20 !important;
  color: #1b5e20 !important;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus {
  border: none;
  -webkit-box-shadow: inset 0 -2px 0 #1b5e20 !important;
  box-shadow: inset 0 -2px 0 #1b5e20 !important;
  color: #1b5e20 !important;
}

/************************************************************
**
**  tooltip
**
*************************************************************/

/* Tooltip */

.black-tooltip + .tooltip > .tooltip-inner {
  background-color: #000;
  color: #ffffff;
  border: none;
  padding: 5px 11px;
  font-size: 12px;
  min-width: 150px;
  float: top;
}

/* Tooltip on top */

.black-tooltip + .tooltip.top > .tooltip-arrow {
  border-top: 5px solid #000;
}

/* Tooltip on left */

.black-tooltip + .tooltip.left > .tooltip-arrow {
  border-left: 5px solid #000;
}

/* Tooltip on left */

.black-tooltip + .tooltip.right > .tooltip-arrow {
  border-right: 5px solid #000;
}

/* Tooltip on left */

.black-tooltip + .tooltip.bottom > .tooltip-arrow {
  border-bottom: 5px solid #000;
}

/* Disable Start Time FullCalendar */

.fc-time {
  display: none;
}

.alert-success-tasks {
  background-color: #00b84a;
  padding: 5px;
  border-radius: 7px;
  color: #fff;
}

.alert-warning-tasks {
  background-color: #fbcf00;
  padding: 5px;
  border-radius: 7px;
  color: #fff;
}

.alert-danger-tasks {
  background-color: #e00031;
  padding: 5px;
  border-radius: 7px;
  color: #fff;
}

.alert-not-tasks {
  background-color: #333;
  padding: 5px;
  border-radius: 7px;
  color: #fff;
}

.container-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.container-flex .item-8 {
  flex-grow: 8;
}

.container-flex .item-1 {
  flex-grow: 2;
}

.display-flex-center-wh-100 {
    display: flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%
}

.display-flex-space-between-wh-100 {
    display: flex;
    -webkit-justify-content: space-between;
    -webkit-align-items: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%
}

.display-flex-end-wh-100 {
    display: flex;
    -webkit-justify-content: flex-end;
    -webkit-align-items: center;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%
}

.license {
    font-size: 27px;
    font-weight: 200;
}

.development-page {
    flex-direction: column !important;
}

.development-page h3 {
    margin: 0px;
    padding: 24px 0px;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: sans-serif;
}

.development-page p {
    font-size: 16px;
    margin: 0px;
    font-weight: 400;
    padding: 10px 0px;
}

.development-page a {
    margin-top: 15px;
}

.circle {
    width: 16px;
    height: 16px;
    border-left: 1px solid #2E7D32;
    border-right: 1px solid #2E7D32;
    position: absolute;
    top: 0px;
    right: 18px;
    border-radius: 50%;
    animation: loading linear .4s infinite;
}

.circle-internal {
    width: 16px;
    height: 16px;
    border-left: 1px solid #2E7D32;
    border-right: 1px solid #2E7D32;
    position: relative;
    top: 0px;
    right: -10px;
    border-radius: 50%;
    animation: loading linear .4s infinite;
}

.circle-type-2 {
    width: 16px;
    height: 16px;
    border-left: 1px solid #2E7D32;
    position: absolute;
    top: 0px;
    right: 18px;
    border-radius: 50%;
    animation: loading linear .4s infinite;
}

.circle-icon {
    width: 16px;
    height: 16px;
    border-left: 1px solid white;
    border-radius: 50%;
    animation: loading linear .4s infinite;
}

.circle-icon.green{
    border-left: 1px solid #2E7D32;
}

.count_message2 {
    background-color: smoke;
    margin-top: -20px;
    margin-right: 5px;
}

#office {
    resize: none;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.col-buttons {
    width: 140px !important;
}

.panel-title-sub {
    font-size: 14px;
}

.color_user {
    width: 24px;
    height: 24px;
}

.display-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.owner.profile_view {
    background-color: #1f8128e3;
    color: white;
}

.owner.profile_view h2 {
    color: white;
}

.swal-button--no:not([disabled]):hover {
    background-color: red;
}

.swal-button--yes:not([disabled]):hover {
    background-color: green;
}

.swal-button.swal-button--yes {
    background-color: green;
}

.swal-button.swal-button--no {
    background-color: red;
}

.swal-overlay {
    z-index: 9999999;
}

.task-on {
    background: green;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
}

.task-of {
    background: #fdcb6e;
    padding: 5px 10px;
    border-radius: 10px;
}

.modal-height-75vh {
    height: 75vh;
}

.display-space-between {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.display-flex-end {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.btn .circule-btn {
    height: 20px;
    width: 20px;
    border-bottom: 1px solid white;
    border-left: 1px solid white;
    border-right: 1px solid white;
    position: absolute;
    left: 30px;
    top: 8px;
    border-radius: 50%;
    animation: loading linear .4s infinite;
}

.display-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.display-flex-center-bottom {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
}

.display-flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.display-flex-start-bottom {
    display: flex;
    justify-content: start;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
}

input[type="checkbox"].lg:after {
    width: 35px !important;
    height: 35px !important;
}

input[type="checkbox"].lg:checked:before {
    left: 12px !important;
    width: 10px !important;
    height: 22px !important;
}

a.remove {
    cursor: pointer;
}

.modal-body.max-height-400 {
    overflow-y: auto;
    max-height: 400px;
    min-height: 400px;
}

.m-0 {
    margin: 0px !important;
}

.mb-1 {
    margin: 1rem !important;
}

.cursor-pointer {
    cursor: pointer;
}

.teste {
    width: 100px;
    height: 100px;
    background: #00B84A;
    display: none;
    animation: translate linear 10s;
}

@keyframes translate {
    0% {
        transform: translateX(5000px);
    }
    100% {
        transform: translateX(0px);
    }
}

.img-block {
    max-width: 100%;
}

.fixed-top-2 {
    position: fixed;
    top: 2rem;
}

.fixed-bottom-4 {
    position: fixed;
    bottom: 4rem;
}

.see-img-box .btn-back {
    right: 2rem;
}

.bg-gray {
    background: #ccc;
    ;
}

.min-height-100vh {
    min-height: 100vh;
}

.spinner {
    display: inline-block;
    height: 20px;
    width: 20px;
    border: 2px solid white;
    border-right-color: transparent;
    border-radius: 50%;
    animation: loading linear .4s infinite;
}


.t-header{
    overflow-y: scroll;
    min-width: 1250px;
}
.t-header table{
    margin-bottom: 0px;
    table-layout: fixed;
}

.t-header table thead tr{
    padding-left: 6px;
}

.t-header::-webkit-scrollbar-track {
    display:none
}

.t-header::-webkit-scrollbar {
    width: 6px;
}

.t-body table{
    table-layout: fixed;
}

.t-body table tr td{
    word-break: break-all;
}


.t-body::-webkit-scrollbar {
    width: 6px;
}
.t-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
.t-body::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.word-break{
    word-break: break-word;
}

.project-list .t-header,.project-list .t-body{
    min-width: 2414px;
}

.project-list .col-description{
    width: 400px;
}

.project-list .col-date{
    width:120px
}

.project-list .col-last-activity{
    width: 130px;
}

.project-list .col-proposal-value{
    width: 184px;
}

.project-list .col-budgeted-expenses{
    width: 184px;
}

.project-list .col-actions{
    width:165px;
}

.project-list .col-number-proposal{
    width:160px;
}

.cell-custom-1{
    width: 120px;
}

.cell-custom-2{
    width: 160px;
}

.allocation-list .t-body{
    max-height: 600px;
}

.allocation-list .t-header{
    margin-bottom: -17px;
}

#modal-allocations .t-header{
    margin-top:10px;
    min-width: 1200px;
}

#modal-allocations .t-body{
    min-width: 1200px;
}

.new-allocation{
    margin-bottom: 20px;
}

.allocation-list .t-header table thead tr th{
    padding:10px;
}


.allocation-list .col-compiled_cod{
    width:140px;
}

.col-project-description{
    width: 350px;
}

.allocation-list .col-dates{
    width:100px
}

.allocation-list .col-hours{
    width: 130px;
}

.allocation-list .col-hours-allocation{
    width: 160px;
}

.allocation-list .col-totals{
    width: 184px;
}


.allocation-list .col-total-expenses{
    width: 138px;
}

.allocation-list .col-total-project{
    width: 138px;
}

.allocation-list .col-total-actions{
    width: 190px;
}

.col-type-services{
    width: 200px;
}

.col-width-130{
    width:130px
}

.col-width-150{
    width:150px;
}

.col-width-180{
    width: 180px;
}

.col-width-230{
    width: 230px;
}

.col-width-320{
  width: 320px;
}

.billed .t-body, .billed .t-header{
    width:1800px
}

.accumulatedBilling .t-body, .accumulatedBilling .t-header{
    width:2000px
}

@media (min-width: 992px){
    .modal-lg {
        width: 990px !important;
    }

    .col-desktop{
        display: block;
    }

    .col-desktop .period-create-text{
        padding-left: 10px;
    }

    .col-mobile{
        display: none;
    }
}

@media (min-width: 1200px){
    .modal-lg {
        width: 1100px !important;
    }

    .form-add-item-budget #add-item{
        margin-top: 0px;
        margin-bottom: 20px;
    }
}


.pl-1{
    padding-left: 1rem;
}

.pr-1{
    padding-right: 1rem;
}
.mr-1{
    margin-right: 1rem;
}

.form-add-item-budget .form-group span.help-block{
    min-height: 20px;
}

.form-add-item-budget #add-item{
    margin-top: -20px;
}

.month-billing .t-header, .month-billing .t-body{
  display: flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.display-flex-space-between-wh-100 {
  display: flex;
  -webkit-justify-content: space-between;
  -webkit-align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.display-flex-end-wh-100 {
  display: flex;
  -webkit-justify-content: flex-end;
  -webkit-align-items: center;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.license {
  font-size: 27px;
  font-weight: 200;
}

.development-page {
  flex-direction: column !important;
}

.development-page h3 {
  margin: 0px;
  padding: 24px 0px;
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: sans-serif;
}

.development-page p {
  font-size: 16px;
  margin: 0px;
  font-weight: 400;
  padding: 10px 0px;
}

.development-page a {
  margin-top: 15px;
}

.circle {
  width: 16px;
  height: 16px;
  border-left: 1px solid #2e7d32;
  border-right: 1px solid #2e7d32;
  position: absolute;
  top: 0px;
  right: 18px;
  border-radius: 50%;
  animation: loading linear 0.4s infinite;
}

.circle-type-2 {
  width: 16px;
  height: 16px;
  border-left: 1px solid #2e7d32;
  position: absolute;
  top: 0px;
  right: 18px;
  border-radius: 50%;
  animation: loading linear 0.4s infinite;
}

.circle-icon {
  width: 16px;
  height: 16px;
  border-left: 1px solid white;
  border-radius: 50%;
  animation: loading linear 0.4s infinite;
}

.circle-icon.green {
  border-left: 1px solid #2e7d32;
}

.count_message2 {
  background-color: smoke;
  margin-top: -20px;
  margin-right: 5px;
}

#office {
  resize: none;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.col-buttons {
  width: 140px !important;
}

.panel-title-sub {
  font-size: 14px;
}

.color_user {
  width: 24px;
  height: 24px;
}

.display-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.owner.profile_view {
  background-color: #1f8128e3;
  color: white;
}

.owner.profile_view h2 {
  color: white;
}

.swal-button--no:not([disabled]):hover {
  background-color: red;
}

.swal-button--yes:not([disabled]):hover {
  background-color: green;
}

.swal-button.swal-button--yes {
  background-color: green;
}

.swal-button.swal-button--no {
  background-color: red;
}

.swal-overlay {
  z-index: 9999999;
}

.task-on {
  background: green;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
}

.task-of {
  background: #fdcb6e;
  padding: 5px 10px;
  border-radius: 10px;
}

.modal-height-75vh {
  height: 75vh;
}

.display-space-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.display-flex-end {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.btn .circule-btn {
  height: 20px;
  width: 20px;
  border-bottom: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  position: absolute;
  left: 30px;
  top: 8px;
  border-radius: 50%;
  animation: loading linear 0.4s infinite;
}

.display-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.display-flex-center-bottom {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
}

.display-flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.display-flex-start-bottom {
  display: flex;
  justify-content: start;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
}

input[type="checkbox"].lg:after {
  width: 35px !important;
  height: 35px !important;
}

input[type="checkbox"].lg:checked:before {
  left: 12px !important;
  width: 10px !important;
  height: 22px !important;
}

a.remove {
  cursor: pointer;
}

.modal-body.max-height-400 {
  overflow-y: auto;
  max-height: 400px;
  min-height: 400px;
}

.m-0 {
  margin: 0px !important;
}

.mb-1 {
  margin: 1rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.teste {
  width: 100px;
  height: 100px;
  background: #00b84a;
  display: none;
  animation: translate linear 10s;
}

@keyframes translate {
  0% {
    transform: translateX(5000px);
  }
  100% {
    transform: translateX(0px);
  }
}

.img-block {
  max-width: 100%;
}

.fixed-top-2 {
  position: fixed;
  top: 2rem;
}

.fixed-bottom-4 {
  position: fixed;
  bottom: 4rem;
}

.see-img-box .btn-back {
  right: 2rem;
}

.bg-gray {
  background: #ccc;
}

.min-height-100vh {
  min-height: 100vh;
}

.spinner {
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 2px solid white;
  border-right-color: transparent;
  border-radius: 50%;
  animation: loading linear 0.4s infinite;
}

.t-header {
  overflow-y: scroll;
  min-width: 1250px;
}
.t-header table {
  margin-bottom: 0px;
  table-layout: fixed;
}

.t-header table thead tr {
  padding-left: 6px;
}

.t-header::-webkit-scrollbar-track {
  display: none;
}

.t-header::-webkit-scrollbar {
  width: 6px;
}

.t-body {
  overflow-y: auto;
  max-height: 600px;
  min-width: 1250px;
}

.t-body table {
  table-layout: fixed;
}

.t-body table tr td {
  word-break: break-all;
}

.t-body::-webkit-scrollbar {
  width: 6px;
}
.t-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.t-body::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.word-break {
  word-break: break-word;
}

.project-list .t-header,
.project-list .t-body {
  min-width: 2414px;
}

.project-list .col-description {
  width: 400px;
}

.project-list .col-date {
  width: 120px;
}

.project-list .col-last-activity {
  width: 130px;
}

.project-list .col-proposal-value {
  width: 184px;
}

.project-list .col-budgeted-expenses {
  width: 184px;
}

.project-list .col-actions {
  width: 165px;
}

.project-list .col-number-proposal {
  width: 160px;
}

.allocation-list .t-header,
.allocation-list .t-body {
  min-width: 5000px;
}

.cell-custom-1 {
  width: 120px;
}

.cell-custom-2 {
  width: 160px;
}

.allocation-list .t-body {
  max-height: 600px;
}

.allocation-list .t-header {
  margin-bottom: 3px;
}

#modal-allocations .t-header {
  margin-top: 10px;
  min-width: 1200px;
}

#modal-allocations .t-body {
  min-width: 1200px;
}

.new-allocation {
  margin-bottom: 20px;
}

.allocation-list .t-header table thead tr th {
  padding: 10px;
}

.allocation-list .col-compiled_cod {
  width: 140px;
}

.col-project-description {
  width: 350px;
}

.allocation-list .col-dates {
  width: 100px;
}

.allocation-list .col-hours {
  width: 130px;
}

.allocation-list .col-hours-allocation {
  width: 160px;
}

.allocation-list .col-totals {
  width: 184px;
}

.allocation-list .col-total-expenses {
  width: 138px;
}

.allocation-list .col-total-project {
  width: 138px;
}

.allocation-list .col-total-actions {
  width: 190px;
}

.col-type-services {
  width: 200px;
}

.col-width-130 {
  width: 130px;
}

.col-width-150 {
  width: 150px;
}

.col-width-180 {
  width: 180px;
}

.col-width-230 {
  width: 230px;
}

.col-width-320 {
  width: 320px;
}

.billed .t-body,
.billed .t-header {
  width: 1800px;
}

.accumulatedBilling .t-body,
.accumulatedBilling .t-header {
  width: 2000px;
}

@media (min-width: 992px) {
  .modal-lg {
    width: 990px !important;
  }

  .col-desktop {
    display: block;
  }

  .col-desktop .period-create-text {
    padding-left: 10px;
  }

  .col-mobile {
    display: none;
  }
}

@media (min-width: 1200px) {
  .modal-lg {
    width: 1100px !important;
  }

  .form-add-item-budget #add-item {
    margin-top: 0px;
    margin-bottom: 20px;
  }
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}
.mr-1 {
  margin-right: 1rem;
}

.form-add-item-budget .form-group span.help-block {
  min-height: 20px;
}

.form-add-item-budget #add-item {
  margin-top: -20px;
}

.month-billing .t-header,
.month-billing .t-body {
  width: 100%;
}

.month-billing .t-header,
.month-billing .t-body table {
  margin-bottom: 3px;
}

.unit-of-measurements .t-header,
.unit-of-measurements .t-body {
  min-width: 200px !important;
  width: 100% !important;
}

.concepts .t-header,
.concepts .t-body {
  min-width: 200px !important;
  width: 100% !important;
}

.align-stretch{
    align-items: stretch;
}

#type_of_consultant .display-flex-start button#attach_document {
  border-radius: 4px 0px 0px 4px;
}

#type_of_consultant .display-flex-start .box-file-info {
  width: 63%;
  border: 1px solid;
  border-left: none;
  border-radius: 0px 4px 4px 0px;
  display: flex;
  align-items: center;
  padding-left: 8px;
}

#type_of_consultant .display-flex-start .box-file-info a{
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

button.btn.dropdown-toggle.btn-default:disabled{
  cursor: not-allowed;
}

@media (max-width: 990px) {
  .col-desktop {
    display: none;
  }

  .col-mobile {
    display: block;
  }

  .dropdown-menu.dropdown-menu-right{
    min-width: 200px;
  }
}

.commercial-proposals .t-body{
  min-width: 3000px
}

.align-items-strech{
  align-items: stretch;
}

.box-group-add{
  display: flex;
  height: fit-content;
  align-self: end;
}

.w-100{
  width: 100%;
}

.display-none{
  display: none !important;
}

#table-additions .t-body, #table-additions .t-header{
  min-width: auto;
  width: 100%;
}

.mt-2{
  margin-top: 2rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.inline-block{
  display: inline-block;
}

.table-cities{
  min-width: 1090px !important;
}

.overflow-ellipsis {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}

.red-emphasis {
  color: red;
}

.opacity-0{
  opacity: 0;
}

.help-block{
  min-height: 15px;
}

.delete-proposal-presented{
  cursor: pointer;
}




.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.4;
  opacity: 0;
  filter: alpha(opacity=0);
}

.tooltip.in {
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}

.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}

.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}

.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}


.skeleton {
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.skeleton .line {
  height: 20px;
  background-color: #ddd;
  margin-bottom: 15px;
  border-radius: 5px;
}

.skeleton .line.short {
  width: 60%;
}

.skeleton .line.medium {
  width: 80%;
}

@keyframes loading {
  0% {
      background-position: 200% 0;
  }
  100% {
      background-position: -200% 0;
  }
}