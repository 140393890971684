.user-dropdown {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 178px;
    float: left;
}

.nav.side-menu > li.active > a {
    background: rgba(255, 255, 255, 0.05) !important;
}

.nav.side-menu > li.current-page, .nav.side-menu > li.active {
    border-right: 5px solid #4B7F4F !important;
}

.left_col {
    /* IE10+ */
    background-image: -ms-linear-gradient(top, #1B5E20 50%, #24A830 150%);

    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(top, #1B5E20 50%, #24A830 150%);

    /* Opera */
    background-image: -o-linear-gradient(top, #1B5E20 50%, #24A830 150%);

    /* Webkit (Safari/Chrome 10) */
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #1B5E20), color-stop(150, #24A830));

    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(top, #1B5E20 50%, #24A830 150%);

    /* W3C Markup */
    background-image: linear-gradient(to bottom, #1B5E20 50%, #24A830 150%);
}

.nav-sm .container.body .col-md-3.left_col {
    width: 77px;
    padding: 0;
    z-index: 2;
    position: absolute;
}

.nav-sm .container.body .col-md-3.left_col.menu_fixed {
    position: fixed;
    height: 100%;
}

.nav-sm .container.body .col-md-3.left_col .mCSB_container,
.nav-sm .container.body .col-md-3.left_col .mCustomScrollBox {
    overflow: visible;
}

.nav-sm .hidden-small {
    visibility: hidden;
}

.nav-sm .container.body .right_col {
    padding: 10px 20px;
    margin-left: 77px;
    z-index: 2;
}

.nav-sm .navbar.nav_title {
    width: 70px;
}

.nav-sm .navbar.nav_title a span {
    display: none;
}

.nav-sm .navbar.nav_title a i {
    font-size: 27px;
    margin: 13px 0 0 3px;
}

.site_title i {
    border: 1px solid #EAEAEA;
    padding: 5px 6px;
    border-radius: 50%;
}

.nav-sm .main_container .top_nav {
    display: block;
    margin-left: 70px;
    z-index: 2;
}

.nav-sm .nav.side-menu li a {
    text-align: center !important;
    font-weight: 400;
    font-size: 10px;
    padding: 10px 5px;
}

.nav-sm .nav.child_menu li.active,
.nav-sm .nav.side-menu li.active-sm {
    border-right: 5px solid #4B7F4F !important;
}

.nav-sm ul.nav.child_menu ul,
.nav-sm .nav.side-menu li.active-sm ul ul {
    position: static;
    width: 200px;
    background: none;
}

.nav-sm > .nav.side-menu > li.active-sm > a {
    color: #1ABB9C !important;
}

.nav-sm .nav.side-menu li a i.toggle-up {
    display: none !important;
}

.nav-sm .nav.side-menu li a i {
    font-size: 25px !important;
    text-align: center;
    width: 100% !important;
    margin-bottom: 5px;
}

.nav-sm ul.nav.child_menu {
    left: 100%;
    position: absolute;
    top: 0;
    width: 210px;
    z-index: 4000;
    background: #4B7F4F;
    display: none;
    border-left: 1px solid #bbb;
}

.nav-sm ul.nav.child_menu li {
    padding: 0 10px;
}

.nav-sm ul.nav.child_menu li a {
    text-align: left !important;
}

.nav-sm .profile {
    display: none;
}

.menu_section {
    margin-bottom: 35px;
}

.menu_section h3 {
    padding-left: 23px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-weight: bold;
    font-size: 11px;
    margin-bottom: 0;
    margin-top: 0;
    text-shadow: 1px 1px #000;
}

.menu_section > ul {
    margin-top: 10px;
}

.profile_pic {
    width: 35%;
    float: left;
}

.img-circle.profile_img {
    width: 60px;
    background: #fff;
    margin-left: 15%;
    z-index: 1000;
    position: inherit;
    margin-top: 20px;
    border: 1px solid rgba(52, 73, 94, 0.44);
    padding: 4px;
}

.profile_info {
    padding: 25px 10px 10px;
    width: 65%;
    float: left;
}

.profile_info span {
    font-size: 13px;
    line-height: 30px;
    color: #BAB8B8;
}

.profile_info h2 {
    font-size: 14px;
    color: #ECF0F1;
    margin: 0;
    font-weight: 300;
}

.profile.img_2 {
    text-align: center;
}

.profile.img_2 .profile_pic {
    width: 100%;
}

.profile.img_2 .profile_pic .img-circle.profile_img {
    width: 50%;
    margin: 10px 0 0;
}

.profile.img_2 .profile_info {
    padding: 15px 10px 0;
    width: 100%;
    margin-bottom: 10px;
    float: left;
}

.main_menu span.fa {
    float: right;
    text-align: center;
    margin-top: 5px;
    font-size: 10px;
    min-width: inherit;
    color: #C4CFDA;
}

.active a span.fa {
    text-align: right !important;
    margin-right: 4px;
}

.nav-sm .menu_section {
    margin: 0;
}

.nav-sm span.fa,
.nav-sm .menu_section h3 {
    display: none;
}

.nav-sm li li span.fa {
    display: inline-block;
}

.nav_menu {
    float: left;
    background: #EDEDED;
    border-bottom: 1.5px solid #1b5e20;
    margin-bottom: 10px;
    width: 100%;
    position: relative;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

@media (min-width: 480px) {
    .nav_menu {
        position: static;
    }
}
.nav-md .container.body .col-md-3.left_col {
    width: 230px;
    padding: 0;
    position: absolute;
    display: flex;
}

.nav-md .container.body .col-md-3.left_col.menu_fixed {
    height: 100%;
    position: fixed;
}

body .container.body .right_col {
    background: #F7F7F7;
}

.nav-md .container.body .right_col {
    padding: 10px 20px 0;
    margin-left: 230px;
}

.nav_title {
    width: 230px;
    float: left;
    background: #1b5e20;
    border-radius: 0;
    height: 57px;
}

@media (max-width: 991px) {
    .nav-md .container.body .right_col, .nav-md .container.body .top_nav {
        width: 100%;
        margin: 0;
    }

    .nav-md .container.body .col-md-3.left_col {
        display: none;
    }

    .nav-md .container.body .right_col {
        width: 100%;
        padding-right: 0;
    }

    .right_col {
        padding: 10px !important;
    }
}
@media (max-width: 1200px) {
    .x_title h2 {
        width: 62%;
        font-size: 17px;
    }

    .tile, .graph {
        zoom: 85%;
        height: inherit;
    }
}
@media (max-width: 1270px) and (min-width: 192px) {
    .x_title h2 small {
        display: none;
    }
}
.left_col .mCSB_scrollTools {
    width: 6px;
}

.left_col .mCSB_dragger {
    max-height: 400px !important;
}

/**  ------------------------------------------  **/
.blue {
    color: #3498DB;
}

.purple {
    color: #9B59B6;
}

.green {
    color: #1ABB9C;
}

.aero {
    color: #9CC2CB;
}

.red {
    color: #E74C3C;
}

.dark {
    color: #34495E;
}

.border-blue {
    border-color: #3498DB !important;
}

.border-purple {
    border-color: #9B59B6 !important;
}

.border-green {
    border-color: #1ABB9C !important;
}

.border-aero {
    border-color: #9CC2CB !important;
}

.border-red {
    border-color: #E74C3C !important;
}

.border-dark {
    border-color: #34495E !important;
}

.bg-white {
    background: #fff !important;
    border: 1px solid #fff !important;
    color: #73879C;
}

.bg-green {
    background: #1ABB9C !important;
    border: 1px solid #1ABB9C !important;
    color: #fff;
}

.bg-red {
    background: #E74C3C !important;
    border: 1px solid #E74C3C !important;
    color: #fff;
}

.bg-blue {
    background: #3498DB !important;
    border: 1px solid #3498DB !important;
    color: #fff;
}

.bg-orange {
    background: #F39C12 !important;
    border: 1px solid #F39C12 !important;
    color: #fff;
}

.bg-purple {
    background: #9B59B6 !important;
    border: 1px solid #9B59B6 !important;
    color: #fff;
}

.bg-blue-sky {
    background: #50C1CF !important;
    border: 1px solid #50C1CF !important;
    color: #fff;
}

.container {
    width: 100%;
    padding: 0;
}

.navbar-nav > li > a, .navbar-brand, .navbar-nav > li > a {
    color: #fff !important;
}

.top_nav .nav > li > a:focus, .top_nav .nav > li > a:hover, .top_nav .nav .open > a, .top_nav .nav .open > a:focus, .top_nav .nav .open > a:hover {
    background: #D9DEE4;
}

body {
    color: #565656;
    background: #1f8128;
    font-family: "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.471;
}

.main_container .top_nav {
    display: block;
    margin-left: 230px;
}

.main_container {
    min-height: 100%;
}

.no-padding {
    padding: 0 !important;
}

.page-title {
    width: 100%;
    height: 65px;
    padding: 10px 0;
}

.page-title .title_left {
    width: 45%;
    float: left;
    display: block;
}

.page-title .title_left h3 {
    margin: 9px 0;
}

.page-title .title_right {
    width: 55%;
    float: left;
    display: block;
}

.page-title .title_right .pull-right {
    margin: 10px 0;
}

.fixed_height_320 {
    height: 320px;
}

.fixed_height_390 {
    height: 390px;
}

.fixed_height_200 {
    height: 200px;
}

.overflow_hidden {
    overflow: hidden;
}

.progress-bar-dark {
    background-color: #34495E !important;
}

.progress-bar-gray {
    background-color: #BDC3C7 !important;
}

table.no-margin .progress {
    margin-bottom: 0;
}

.main_content {
    padding: 10px 20px;
}

.col-md-55 {
    width: 50%;
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .col-md-55 {
        width: 20%;
    }
}
@media (min-width: 992px) {
    .col-md-55 {
        width: 20%;
    }
}
@media (min-width: 1200px) {
    .col-md-55 {
        width: 20%;
    }
}
@media (min-width: 192px) and (max-width: 1270px) {
    table.tile_info span.right {
        margin-right: 7px;
        float: left;
    }
}
.center-margin {
    margin: 0 auto;
    float: none !important;
}

.col-md-55, .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.row {
    margin-right: -10px;
    margin-left: -10px;
}

.grid_slider .col-md-6 {
    padding: 0 40px;
}

h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 10px;
    margin-bottom: 10px;
}

a {
    color: #5A738E;
    text-decoration: none;
}

a, a:visited, a:focus, a:active, :visited, :focus, :active, .btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
    outline: 0;
}

a:hover, a:focus {
    text-decoration: none;
}

.navbar {
    margin-bottom: 0;
}

.navbar-header {
    background: #34495E;
}

.navbar-right {
    margin-right: 0;
}

.top_nav .navbar-right {
    margin: 0;
    float: right;
}

.top_nav .navbar-right li {
    display: inline-block;
    float: right;
    position: static;
}

@media (min-width: 480px) {
    .top_nav .navbar-right li {
        position: relative;
    }
}
.top_nav .dropdown-menu li {
    width: 100%;
}

.top_nav .dropdown-menu li a {
    width: 100%;
    padding: 12px 20px;
}

.top_nav li a i {
    font-size: 15px;
}

.navbar-static-top {
    position: fixed;
    top: 0;
    width: 100%;
}

.sidebar-header {
    border-bottom: 0;
    margin-top: 46px;
}

.sidebar-header:first-of-type {
    margin-top: 0;
}

.nav.side-menu > li {
    position: relative;
    display: block;
    cursor: pointer;
}

.nav.side-menu > li > a {
    margin-bottom: 6px;
}

.nav.side-menu > li > a:hover {
    color: #F2F5F7 !important;
}

.nav.side-menu > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background: transparent;
}

.nav.child_menu {
    display: none;
}

.nav.child_menu li:hover,
.nav.child_menu li.active {
    background-color: rgba(255, 255, 255, 0.06);
}

.nav.child_menu li {
    padding-left: 36px;
}

.nav-md ul.nav.child_menu li:before {
    background: #E5E5E5 !important;
    bottom: auto;
    content: "";
    height: 8px;
    left: 23px;
    margin-top: 15px;
    position: absolute;
    right: auto;
    width: 8px;
    z-index: 1;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.nav-md ul.nav.child_menu li:after {
    border-left: 1px solid #ddd;
    bottom: 0;
    content: "";
    left: 27px;
    position: absolute;
    top: 0;
}

.nav.side-menu > li > a, .nav.child_menu > li > a {
    color: #E7E7E7;
    font-weight: 500;
}

.nav.child_menu li li:hover,
.nav.child_menu li li.active {
    background: none;
}

.nav.child_menu li li a:hover,
.nav.child_menu li li a.active {
    color: #fff;
}

.nav > li > a {
    position: relative;
    display: block;
    padding: 13px 15px 12px;
}

.nav li.current-page {
    background: rgba(255, 255, 255, 0.05);
}

.nav li li li.current-page {
    background: none;
}

.nav li li.current-page a {
    color: #fff;
}

.nav.side-menu > li.active > a {
    text-shadow: rgba(0, 0, 0, 0.25) 0 -1px 0;
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #5b6479), color-stop(100%, #4c5566)), #686e78;
    background: -webkit-linear-gradient(#334556, #2C4257), #2A3F54;
    background: -moz-linear-gradient(#334556, #2C4257), #2A3F54;
    background: -o-linear-gradient(#334556, #2C4257), #2A3F54;
    background: linear-gradient(#334556, #2C4257), #2A3F54;
    -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0;
    -moz-box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0;
    box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0;
}

.navbar-brand, .navbar-nav > li > a {
    font-weight: 500;
    color: #ECF0F1 !important;
    margin-left: 0 !important;
    line-height: 32px;
}

.site_title {
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
    font-size: 22px;
    width: 100%;
    color: #ECF0F1 !important;
    margin-left: 0 !important;
    line-height: 59px;
    display: block;
    height: 55px;
    margin: 0;
    padding-left: 10px;
}

.site_title:hover, .site_title:focus {
    text-decoration: none;
}

.nav.navbar-nav > li > a {
    color: #515356 !important;
}

.nav.top_menu > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
    color: #34495E !important;
}

.nav > li > a:hover, .nav > li > a:focus {
    background-color: transparent;
}

.top_search {
    padding: 0;
}

.top_search .form-control {
    border-right: 0;
    box-shadow: inset 0 1px 0px rgba(0, 0, 0, 0.075);
    border-radius: 25px 0px 0px 25px;
    padding-left: 20px;
    border: 1px solid rgba(221, 226, 232, 0.49);
}

.top_search .form-control:focus {
    border: 1px solid rgba(221, 226, 232, 0.49);
    border-right: 0;
}

.top_search .input-group-btn button {
    border-radius: 0px 25px 25px 0px;
    border: 1px solid rgba(221, 226, 232, 0.49);
    border-left: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    color: #93A2B2;
    margin-bottom: 0 !important;
}

.toggle {
    float: right;
    margin: 0;
    width: 70px;
}

.toggle a {
    padding: 15px 15px 0;
    margin: 0;
    cursor: pointer;
}

.toggle a i {
    font-size: 26px;
}

.nav.child_menu > li > a {
    color: rgba(255, 255, 255, 0.75);
    font-size: 12px;
    padding: 9px;
}

.panel_toolbox {
    float: right;
    min-width: 70px;
}

.panel_toolbox > li {
    float: left;
    cursor: pointer;
}

.panel_toolbox > li > a {
    padding: 5px;
    color: #C5C7CB;
    font-size: 14px;
}

.panel_toolbox > li > a:hover {
    background: #F5F7FA;
}

.line_30 {
    line-height: 30px;
}

.main_menu_side {
    padding: 0;
}

.bs-docs-sidebar .nav > li > a {
    display: block;
    padding: 4px 6px;
}

footer {
    background: #F7F7F7;
    padding: 15px 20px;
    display: block;
}

.footer_fixed footer {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
}

@media (min-width: 768px) {
    .footer_fixed footer {
        margin-left: 0;
    }
}
@media (min-width: 768px) {
    .footer_fixed .nav-sm footer {
        margin-left: 0;
    }
}
.tile-stats.sparkline {
    padding: 10px;
    text-align: center;
}

.jqstooltip {
    background: #34495E !important;
    width: 30px !important;
    height: 22px !important;
    text-decoration: none;
}

.tooltip {
    display: block !important;
}

.tiles {
    border-top: 1px solid #ccc;
    margin-top: 15px;
    padding-top: 5px;
    margin-bottom: 0;
}

.tile {
    overflow: hidden;
}

.top_tiles {
    margin-bottom: 0;
}

.top_tiles .tile h2 {
    font-size: 30px;
    line-height: 30px;
    margin: 3px 0 7px;
    font-weight: bold;
}

article.media {
    width: 100%;
}

.dropdown-menu.msg_list span {
    white-space: normal;
}

.dropdown-menu {
    border: medium none;
    box-shadow: none;
    display: none;
    float: left;
    font-size: 12px;
    left: 0;
    list-style: none outside none;
    padding: 0;
    position: absolute;
    text-shadow: none;
    top: 100%;
    z-index: 9998;
    border: 1px solid #D9DEE4;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.dropdown-menu > li > a {
    color: #252525;
}

.navbar-nav .open .dropdown-menu {
    position: absolute;
    background: #fff;
    margin-top: 0;
    border: 1px solid #D9DEE4;
    -webkit-box-shadow: none;
    right: 0;
    left: auto;
    width: 220px;
}

.navbar-nav .open .dropdown-menu.msg_list {
    width: 300px;
}

.tile_count {
    margin-bottom: 20px;
    margin-top: 20px;
}

.tile_count .tile_stats_count {
    border-bottom: 1px solid #D9DEE4;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

@media (min-width: 992px) {
    .tile_count .tile_stats_count {
        margin-bottom: 10px;
        border-bottom: 0;
        padding-bottom: 10px;
    }
}
.tile_count .tile_stats_count:before {
    content: "";
    position: absolute;
    left: 0;
    height: 65px;
    border-left: 2px solid #ADB2B5;
    margin-top: 10px;
}

@media (min-width: 992px) {
    .tile_count .tile_stats_count:first-child:before {
        border-left: 0;
    }
}
.tile_count .tile_stats_count .count {
    font-size: 30px;
    line-height: 47px;
    font-weight: 600;
}

@media (min-width: 768px) {
    .tile_count .tile_stats_count .count {
        font-size: 40px;
    }
}
@media (min-width: 992px) and (max-width: 1100px) {
    .tile_count .tile_stats_count .count {
        font-size: 30px;
    }
}
.tile_count .tile_stats_count span {
    font-size: 12px;
}

@media (min-width: 768px) {
    .tile_count .tile_stats_count span {
        font-size: 16px;
    }
}
.tile_count .tile_stats_count .count_bottom i {
    width: 12px;
}

/** /top tiles **/

.dashboard_graph .col-md-9, .dashboard_graph .col-md-3 {
    padding: 0;
}

a.user-profile {
    color: #5E6974 !important;
}

.user-profile img, .user-profile i {
    width: 29px;
    height: 29px;
    border-radius: 50%;
    margin-right: 10px;
}

ul.top_profiles {
    height: 330px;
    width: 100%;
}

ul.top_profiles li {
    margin: 0;
    padding: 3px 5px;
}

ul.top_profiles li:nth-child(odd) {
    background-color: #eee;
}

.media .profile_thumb {
    border: 1px solid;
    width: 50px;
    height: 50px;
    margin: 5px 10px 5px 0;
    border-radius: 50%;
    padding: 9px 12px;
}

.media .profile_thumb i {
    font-size: 30px;
}

.media .date {
    background: #ccc;
    width: 52px;
    margin-right: 10px;
    border-radius: 10px;
    padding: 5px;
}

.media .date .month {
    margin: 0;
    text-align: center;
    color: #fff;
}

.media .date .day {
    text-align: center;
    color: #fff;
    font-size: 27px;
    margin: 0;
    line-height: 27px;
    font-weight: bold;
}

.event .media-body a.title {
    font-weight: bold;
}

.event .media-body p {
    margin-bottom: 0;
}

h4.graph_title {
    margin: 7px;
    text-align: center;
}

/* *********  /widgets  *************************************** */
/* *********  iconts-display  **************************** */
.fontawesome-icon-list .fa-hover a:hover {
    background-color: #ddd;
    color: #fff;
    text-decoration: none;
}

.fontawesome-icon-list .fa-hover a {
    display: block;
    line-height: 32px;
    height: 32px;
    padding-left: 10px;
    border-radius: 4px;
}

.fontawesome-icon-list .fa-hover a:hover .fa {
    font-size: 28px;
    vertical-align: -6px;
}

.fontawesome-icon-list .fa-hover a .fa {
    width: 32px;
    font-size: 16px;
    display: inline-block;
    text-align: right;
    margin-right: 10px;
}

.main_menu .fa {
    width: 26px;
    opacity: .99;
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.tabs-left, .tabs-right {
    border-bottom: none;
    padding-top: 2px;
}

.tabs-left {
    border-right: 1px solid #F7F7F7;
}

.tabs-right {
    border-left: 1px solid #F7F7F7;
}

.tabs-left > li, .tabs-right > li {
    float: none;
    margin-bottom: 2px;
}

.tabs-left > li {
    margin-right: -1px;
}

.tabs-right > li {
    margin-left: -1px;
}

.tabs-left > li.active > a, .tabs-left > li.active > a:hover, .tabs-left > li.active > a:focus {
    border-bottom-color: #F7F7F7;
    border-right-color: transparent;
}

.tabs-right > li.active > a, .tabs-right > li.active > a:hover, .tabs-right > li.active > a:focus {
    border-bottom: 1px solid #F7F7F7;
    border-left-color: transparent;
}

.tabs-left > li > a {
    border-radius: 4px 0 0 4px;
    margin-right: 0;
    display: block;
    background: #F7F7F7;
    text-overflow: ellipsis;
    overflow: hidden;
}

.tabs-right > li > a {
    border-radius: 0 4px 4px 0;
    margin-right: 0;
    background: #F7F7F7;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sideways {
    margin-top: 50px;
    border: none;
    position: relative;
}

.sideways > li {
    height: 20px;
    width: 120px;
    margin-bottom: 100px;
}

.sideways > li > a {
    border-bottom: 1px solid #ddd;
    border-right-color: transparent;
    text-align: center;
    border-radius: 4px 4px 0px 0px;
}

.sideways > li.active > a, .sideways > li.active > a:hover, .sideways > li.active > a:focus {
    border-bottom-color: transparent;
    border-right-color: #ddd;
    border-left-color: #ddd;
}

.sideways.tabs-left {
    left: -50px;
}

.sideways.tabs-right {
    right: -50px;
}

.sideways.tabs-right > li {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sideways.tabs-left > li {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

body {
    margin: 0 !important;
    overflow-x: hidden;
}

.sidebar-footer {
    bottom: 0px;
    clear: both;
    display: block;
    padding: 5px 0 0 0;
    position: fixed;
    width: 230px;
    background: #0c3810;
}
.sidebar-footer span {
    color: #ddd;
}
.sidebar-footer a {
    padding: 7px 0 3px;
    text-align: center;
    width: 100%;
    font-size: 17px;
    display: block;
    float: left;
    background: #144417;
}
.sidebar-footer a:hover {
    background: rgb(12, 56, 16);
}

.navbar {
    box-shadow: none;
}