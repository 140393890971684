.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 999px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    background: red;
    cursor: inherit;
    display: block;
}
input[readonly] {
    background-color: white !important;
    cursor: text !important;
    height: 33px;
    border-right:none;
}

@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700,100);