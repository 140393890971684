.info-number .badge {
    font-size: 10px !important;
    font-weight: normal;
    line-height: 13px;
    padding: 2px 6px;
    position: absolute;
    right: 2px;
    top: 8px;
}

.info-number .badge i {
    font-size: 10px !important;
}

ul.msg_list li {
    background: #f7f7f7;
    padding: 5px;
    display: -ms-flexbox;
    display: flex;
    margin: 6px 6px 0;
    width: 96% !important;
}

ul.msg_list li:last-child {
    margin-bottom: 6px;
    padding: 10px;
}

ul.msg_list li a {
    padding: 3px 5px !important;
    color: #565656 !important;
}

ul.msg_list li a .image img {
    border-radius: 2px 2px 2px 2px;
    -webkit-border-radius: 2px 2px 2px 2px;
    float: left;
    margin-right: 10px;
    width: 11%;
}

ul.msg_list li a .time {
    font-size: 11px;
    font-style: italic;
    font-weight: bold;
    position: absolute;
    right: 35px;
}

ul.msg_list li a .message {
    display: block !important;
    font-size: 11px;
}

.dropdown-menu.msg_list span {
    white-space: normal;
}

.scroll {
    max-height: 593px;
    overflow-y: scroll;
    overflow-x: hidden;
}

@media (min-width: 1365px) {
    .scroll {
        max-height: 480px !important;
    }
}

.new {
    background-color: #1abb9c !important;
}

.new span {
    color: #fff !important;
}

a.star {
    color: #428bca !important;
}

.mail_content {
    background: none repeat scroll 0 0 #FFFFFF;
    border-radius: 4px;
    margin-top: 20px;
    min-height: 500px;
    padding: 10px 11px;
    width: 100%;
}

.list-btn-mail {
    margin-bottom: 15px;
}

.list-btn-mail.active {
    border-bottom: 1px solid #39B3D7;
    padding: 0 0 14px;
}

.list-btn-mail > i {
    float: left;
    font-size: 18px;
    font-style: normal;
    width: 33px;
}

.list-btn-mail > .cn {
    background: none repeat scroll 0 0 #39B3D7;
    border-radius: 12px;
    color: #FFFFFF;
    float: right;
    font-style: normal;
    padding: 0 5px;
}

.button-mail {
    margin: 0 0 15px !important;
    text-align: left;
    width: 100%;
}

.btn-group-vertical .btn, .btn-group .btn {
    margin-bottom: 0;
    margin-right: 0;
}

.mail_list_column {
    background-color: #fff;
    padding-top: 10px;
    border-left: 1px solid #DBDBDB;
    border-top: 1px solid #DBDBDB;
    border-bottom: 1px solid #DBDBDB;
}

.mail_view {
    background-color: #fff;
    border: 1px solid #DBDBDB;
}

.mail_list {
    width: 100%;
    border-bottom: 1px solid #DBDBDB;
    margin-bottom: 2px;
    display: inline-block;
}

.mail_list .left {
    width: 5%;
    float: left;
    margin-right: 3%;
}

.mail_list .right {
    width: 90%;
    float: left;
}

.mail_list h3 {
    font-size: 15px;
    font-weight: bold;
    margin: 0px 0 6px;
}

.mail_list h3 small {
    float: right;
    color: #ADABAB;
    font-size: 11px;
    line-height: 20px;
}

.mail_list .badge {
    padding: 3px 6px;
    font-size: 8px;
    background: #BAB7B7;
}

@media (max-width: 767px) {
    .mail_list {
        margin-bottom: 5px;
        display: inline-block;
    }
}

.mail_heading h4 {
    font-size: 18px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-top: 20px;
}

.mail_list_column, .mail_view {
    overflow-y: scroll;
}

.mail_list p {
    color: #666 !important;
}

a.active .mail_list h3{
    color: #1b5e20 !important;
}

.mail_list h3 {
    color: #565656 !important;
}