/* ----- DIALOG/MODAL ----- */
/* FADE/SCALE EFFECT */
fade .modal-dialog, #change_avatart.fade .modal-dialog {
    opacity: 0;
    -webkit-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -o-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

fade.in .modal-dialog, #change_avatart.fade.in .modal-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

/* DIALOG CONTENT */
.modal-content {
    border: none;
    border-radius: 2px;
    -webkit-box-shadow: 0 40px 77px rgba(0, 0, 0, 0.22), 0 27px 24px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 40px 77px rgba(0, 0, 0, 0.22), 0 27px 24px rgba(0, 0, 0, 0.2);
    box-shadow: 0 40px 77px rgba(0, 0, 0, 0.22), 0 27px 24px rgba(0, 0, 0, 0.2);
}

/* DIALOG HEADER */
.modal-header {
    min-height: 16px;
    border-bottom: none;
    background-color: #26852d;
    color: #fff !important;
    border-bottom: 4px solid rgba(22, 75, 26, 0.51);
}

.modal-header button.close{
    color: #fff !important;
}

.modal-title {
    font-weight: 500;
    font-size: 21px;
    color: #fff !important;
}

/* DIALOG BODY */
.modal-body {
    padding-top: 25px;
}

.modal-body p {
    font-weight: 400;
    font-size: 14px;
    color: #212121;
}

.modal-body .lead {
    font-weight: 300;
    font-size: 16px;
    color: #757575;
}

.modal-body p:last-chil ,
.modal-body .lead:last-child {
    margin-bottom: 0;
}

/* DIALOG FOOTER */
.modal-footer {
    margin-top: 24px;
    padding: 8px 0;
    border-top: none;
}

.modal-footer .btn {
    height: 36px;
    margin-right: 8px;
    border: none;
    border-radius: 0;
    text-transform: uppercase;
    font-weight: 500;
}

.modal-footer .btn + .btn {
    margin-left: 0;
}

.modal-footer .btn + .btn:last-child {
    margin-left: -4px;
}